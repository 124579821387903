//@flow
import React, { memo } from 'react';

import {
  getInvariantUserAccount,
  clearSessionAndRedirectToLogin,
} from '@dt/session';
import UserRole from '@dt/enums/UserRoleEnum';

import NewReleaseIcon from '@material-ui/icons/NewReleases';
import UsersIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/HelpOutline';
import AlertsIcon from '@material-ui/icons/Notifications';
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';
import ShareIcon from '@material-ui/icons/Share';
import ActivityIcon from '@material-ui/icons/History';
import StoreIcon from '@material-ui/icons/Store';
import PollIcon from '@material-ui/icons/Poll';
import PersonIcon from '@material-ui/icons/Person';

import { Menu, MenuItem, ListItemIcon } from '@material-ui/core';

import ExtLink from './ExtLink';

type Props = {
  anchorEl: HTMLElement | null,
  handleClose: () => void,
};

const GlobalSettingsMenu = (props: Props) => {
  const { anchorEl, handleClose } = props;
  const { currentUser, accountInfo } = getInvariantUserAccount();
  const scsName = accountInfo.vendor_supply_chain_security_whitelabeling_enabled
    ? accountInfo.vendor_supply_chain_security_whitelabeling_name ||
      'Supply Chain Secure'
    : 'Supply Chain Secure';

  return (
    <Menu
      disableScrollLock
      id="settings-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorReference="anchorEl"
      anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      <ExtLink
        to="/mobile/sdlc/stanford-dish-security-university"
        target="_self"
        onClick={handleClose}
        aria-label="What's New Menu Item"
      >
        <MenuItem>
          <ListItemIcon>
            <NewReleaseIcon />
          </ListItemIcon>
          What&apos;s New
        </MenuItem>
      </ExtLink>

      {currentUser.role === UserRole.MANAGER && (
        <ExtLink
          to="/management/activity"
          target="_self"
          onClick={handleClose}
          aria-label="Activity Menu Item"
        >
          <MenuItem>
            <ListItemIcon>
              <ActivityIcon />
            </ListItemIcon>
            Activity
          </MenuItem>
        </ExtLink>
      )}

      {currentUser.can_access_vendor_supply_chain_security && (
        <ExtLink
          to={
            currentUser.is_accessing_a_vendor
              ? '/management/scs'
              : `/management/scs/vendor/${accountInfo.vendor_id || ''}/`
          }
          target="_self"
          onClick={handleClose}
          aria-label="Vendor Management Menu Item"
        >
          <MenuItem>
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            {scsName}
          </MenuItem>
        </ExtLink>
      )}

      <ExtLink
        to={
          currentUser.role === 'MANAGER'
            ? '/management/users/'
            : '/management/users/invite'
        }
        target="_self"
        onClick={handleClose}
        aria-label="Users Menu Item"
      >
        <MenuItem disabled={!currentUser.can_invite_users}>
          <ListItemIcon>
            <UsersIcon />
          </ListItemIcon>
          {currentUser.role === 'MANAGER' ? 'Manage ' : 'Invite '}Users
        </MenuItem>
      </ExtLink>

      <ExtLink
        to="/mobile/preferences"
        target="_self"
        onClick={handleClose}
        aria-label="Preferences Menu Item"
      >
        <MenuItem>
          <ListItemIcon>
            <AlertsIcon />
          </ListItemIcon>
          Alerts
        </MenuItem>
      </ExtLink>

      <ExtLink
        to="/mobile/shared-links"
        target="_self"
        onClick={handleClose}
        aria-label="Shared Links Menu Item"
      >
        <MenuItem>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          Shared Links
        </MenuItem>
      </ExtLink>

      <ExtLink
        to="/management/compliance-report"
        target="_self"
        onClick={handleClose}
        aria-label="Compliance Report Menu Item"
      >
        <MenuItem>
          <ListItemIcon>
            <PollIcon />
          </ListItemIcon>
          Compliance
        </MenuItem>
      </ExtLink>

      <ExtLink
        to="/management/profile"
        target="_self"
        onClick={handleClose}
        aria-label="Profile Menu Item"
      >
        <MenuItem>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          Profile
        </MenuItem>
      </ExtLink>

      <ExtLink
        to="https://support.securetheorem.com/"
        target="_blank"
        onClick={handleClose}
        aria-label="Help and Support Menu Item"
      >
        <MenuItem>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          Help &amp; Support
        </MenuItem>
      </ExtLink>

      <MenuItem
        onClick={() => {
          clearSessionAndRedirectToLogin();
        }}
        aria-label="Sign Out Menu Item"
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Sign out
      </MenuItem>
    </Menu>
  );
};

export default memo<Props>(GlobalSettingsMenu);
