// @flow
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  type Store as ReduxStore,
  type Middleware,
} from 'redux';
import type { State } from './store_state_type';
import { sagasCreated } from './containers/actions';
import { setTemporaryTokenSuccess } from './atlas/actions';

const composeEnhancers = global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: true,
    })
  : compose;

/*
 * @deprecated - Redux is deprecated in favor of a simpler cache mechanism.
 *               Redux Saga is also deprecated in favor of pushing:
 *
 *               - Aggregations
 *               - Transformations
 *               - Complicated State machines
 *
 *               And others into the backend.
 */
export function buildReducer() {
  return combineReducers<_, *>({
    api_operations: require('./api_operations/reducer').default,
    asset_groups: require('./asset_groups/reducer').default,
    certificate_chains: require('./certificate_chains/reducer').default,
    certificates: require('./certificates/reducer').default,
    cloud_resources: require('./cloud_resources/reducer').default,
    configurations: require('./configurations/reducer.js').default,
    dashboard: require('./dashboard/reducer').default,
    detect_inject: require('./detect_inject/reducer').default,
    domain_names: require('./domain_names/reducer').default,
    errors: require('./atlas/reducers/errors').default,
    events: require('./events/reducer').default,
    firstRequest: require('@dt/progress-indicator').Reducers.createFirstRequestReducer(),
    graphql_apis: require('./graphql_apis/reducer').default,
    hack_extract: require('./hack_extract/reducer').default,
    inventory_export: require('./inventory/export/reducer').default,
    inventory_search: require('./inventory/search/reducer').default,
    ip_ranges: require('./ip_ranges/reducer').default,
    loading: require('./atlas/reducers/loading').default,
    network_services: require('./network_services/reducer').default,
    notifications: require('@dt/notifications').reducer,
    openapi_definitions: require('./openapi_definitions/reducer').default,
    openscanApps: require('./atlas/reducers/openscanApps').default,
    openscanConfig: require('./atlas/reducers/openscanConfig').default,
    pagination: require('@dt/pagination').PaginationReducer,
    policies: require('./policies/reducer').default,
    policyViolations: require('./atlas/reducers/policyViolations').default,
    policy_rule_types: require('./policy_rule_types/reducer').default,
    policy_rules: require('./policy_rules/reducer').default,
    policy_violations: require('./policy_violations/reducer').default,
    progressIndicator: require('@dt/progress-indicator').Reducers
      .progressIndicatorReducer,
    resource_fetch: require('./resource_fetch/reducer').default,
    restful_apis: require('./restful_apis/reducer').default,
    searchResults: require('./atlas/reducers/searchResults').default,
    shadowscan: require('@dt/findings/shadow-scan/reducers').default,
    shared_links: require('./shared_links/reducer').default,
    special_scan_requests: require('./special_scans/reducer').default,
    tls_scan_results: require('./tls_scan_results/reducer').default,
    users: require('./users/reducer').default,
    web_applications: require('./web_applications/reducer').default,
    BillingInfo: require('@dt/billing-info').billingReducer,

    readyOnSharedLinks: (state = false, action) => {
      return action.type === setTemporaryTokenSuccess.toString() ? true : state;
    },
    ready: (state = false, action): boolean => {
      if (action.type === sagasCreated.toString()) return true;
      return state;
    },
  });
}

export function getStore(
  middleware: $ReadOnlyArray<Middleware<*, *>>,
  initialState?: State,
) {
  const middlewareFinal = composeEnhancers(applyMiddleware(...middleware));

  if (initialState) {
    return createStore<State, *, *>(
      buildReducer(),
      initialState,
      // $FlowFixMe
      middlewareFinal,
    );
  } else {
    return createStore<State, *, *>(buildReducer(), middlewareFinal);
  }
}

export type Action<T: string, D, M> = {
  type: T,
  payload?: D,
  meta?: M,
  ...
};

export type Store = ReduxStore<State, *>;
