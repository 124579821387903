//@flow
import Raven from 'raven-js';
import { useEffect, useState } from 'react';
import {
  clearSessionAndRedirectToExpired,
  redirectToLogin,
  getUserAccount,
  REJECT_REASON,
} from '@dt/session';
import { setTemporaryToken } from '@dt/horizon-api';
import { useSelector } from 'react-redux';
import type { State as ReduxState } from './redux/store_state_type';
import type { UserAccount } from '@dt/session';
import type { AccessControl } from '@dt/user-api/users';

type SessionOptions = {
  +unauthenticatedRedirect?: boolean,
};

type SessionWorkflow = {
  +data: null | { +user_account: UserAccount },
  +loading: boolean,
  +error: null | Error,
};

/*
 * Retreives the current actor's authenticated session.
 *
 * There are primarily three workflows.
 * - Authenticated                      => Render page content.
 * - Unauthenticated/Error (Redirect)   => Redirect the user to the login page.
 *                                         This is on by default but can be turned off.
 * - Unauthenticated/Error (Render)     => Render page error.
 *
 * NOTE: Fetch requests still need to be checked for an active session.
 *       This is to ensure that a user is redirected as soon as they use their session and
 *       its no longer active.
 *
 * @param SessionOptions.unauthenticatedRedirect - Redirect if the user is not authenticated.
 *                                                 On by default.
 *
 * @example
 *   const Page = function Page() {
 *     const { data, loading, error } = useSession({ unauthenticatedRedirect: false });
 *     if (error) return <PageError />;
 *     if (loading || !data) return <PageSkeleton />;
 *     return <PageContent />
 *   };
 */
export const useSession = (options?: SessionOptions): SessionWorkflow => {
  const unauthenticatedRedirect =
    typeof options?.unauthenticatedRedirect === 'boolean'
      ? options.unauthenticatedRedirect
      : true;

  const [error, setError] = useState<null | Error>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<null | { +user_account: UserAccount }>(null);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    // Obtain user_account from sevenhell.
    //
    // TODO: This needs to be sorted out. `getUserAccount` is cached.
    //
    //       Caching the user account can have unintended side effects.
    //       This could put the user in an "Unauthenticated" render state where
    //       their session could have expired.
    //
    //       To fix this a request should be made to the gate *every* time this
    //       hook is used.
    getUserAccount()
      .then(result => {
        // Stop if unmounted.
        if (!isMounted) {
          return;
        }

        if (!result.no_session_reason) {
          setData({
            user_account: result,
          });
        } else {
          // Provide error to client call site.
          setError(new Error(result.no_session_reason));
          if (unauthenticatedRedirect) {
            // Redirect when no user account was found.
            if (result.no_session_reason === REJECT_REASON.NO_SESSION_ID) {
              redirectToLogin();
            } else if (
              result.no_session_reason === REJECT_REASON.EXPIRED_SESSION_ID
            ) {
              clearSessionAndRedirectToExpired();
            } else {
              const error = new Error('Response Invalid');
              console.error(error);
              Raven.captureException(error, {
                extra: {
                  msg: 'An error occurred fetching the user account.',
                },
              });
              clearSessionAndRedirectToExpired();
            }
          }
        }
      })
      .catch(e => {
        Raven.captureException(e, {
          extra: { msg: 'An error occurred fetching the user account.' },
        });
        setError(e);
        if (unauthenticatedRedirect) {
          clearSessionAndRedirectToExpired();
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [unauthenticatedRedirect]);

  return { error, data, loading };
};

/*
 * Used for secure share pages.
 * The temporary access token is prepared here.
 *
 * @param token - Temporary access token.
 *
 * @example
 *   const Page = function Page() {
 *     const { loading, error } = useTemporaryAccessToken({ token });
 *     if (error) return <PageError />;
 *     if (loading || !data) return <PageSkeleton />;
 *     return <PageContent />
 *   };
 */
export const useTemporaryAccessToken = ({ token }: { token: ?string }) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const isReady = useSelector((state: ReduxState) => state.ready);

  useEffect(() => {
    if (token) {
      setTemporaryToken(token);
      setIsMounted(true);
    }
  }, [token]);

  let data,
    error = null,
    loading = false;
  if (!token) {
    error = 'No token provided.';
    data = null;
  }
  if (!isReady || !isMounted) {
    loading = true;
    data = null;
  }
  if (token && isReady && isMounted) {
    error = null;
    loading = false;
    data = { token };
  }

  return { error, data, loading };
};

type AuthorizationWorkflow = {
  +isAuthorized: boolean,
};

/*
 * Compares the provided actor's authenticated session with provided access controls.
 *
 * If the user doesn't meet those access controls invalid is returned.
 * If this is a cross-product view, ignore the access list and defer to B/E.
 * Otherwise the user has access.
 *
 * This hook is designed to be a simple access control check against the requested
 * user session.
 */
export const useAuthorization = (
  session: null | { +user_account: UserAccount },
  accessControls: AccessControl[],
  crossProductView?: boolean,
): AuthorizationWorkflow => {
  let isAuthorized = true;
  if (session) {
    if (typeof crossProductView === 'undefined' || !crossProductView) {
      for (const accessControl of accessControls) {
        if (!session.user_account.currentUser[accessControl]) {
          isAuthorized = false;
          break;
        }
      }
    }
  } else {
    isAuthorized = false;
  }

  return { isAuthorized };
};
