//@flow
import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { palette } from '@dt/theme';
import type { Node } from 'react';

type Props = {|
  +style?: mixed,
  +m?: number,
  +mb?: number,
  +mt?: number,
  +p?: number,
  +c?: string,
  +bg?: string,
  +variant: 'info' | 'warning' | 'error' | 'success' | 'disabled',
  +icon?: Node,
  +message: string,
  +messageHeader?: string,
|};

const variantStyle = {
  info: { bgcolor: palette.blue50, color: palette.blue10 },
  warning: { bgcolor: palette.yellow50, color: palette.yellow10 },
  error: { bgcolor: palette.red50, color: palette.red10 },
  success: { bgcolor: palette.green50, color: palette.green10 },
  disabled: { bgcolor: palette.gray50, color: palette.gray10 },
};

let useStyles = makeStyles({
  // use entire given `style` but override
  // display: flex & justifyItems: center
  // to inline icon & text.
  box: props => ({
    ...(props.style && props.style),
    display: 'flex',
    alignItems: 'center',
  }),
});

const MessageComponent = function Message({
  style,
  m,
  mb,
  mt,
  p,
  c,
  bg,
  variant,
  icon,
  message,
  messageHeader,
}: Props) {
  let classes = useStyles({ style });
  const padding = typeof p !== 'undefined' ? p : 1;
  const margin = typeof m !== 'undefined' ? m : 2;
  const marginBottom =
    typeof mb !== 'undefined' ? mb : typeof m !== 'undefined' ? m : 2;
  const marginTop =
    typeof mt !== 'undefined' ? mt : typeof m !== 'undefined' ? m : 2;
  const color = typeof c !== 'undefined' ? c : 'inherit';
  const bgColor =
    typeof bg !== 'undefined' ? bg : variantStyle[variant].bgcolor;

  return (
    <Box
      bgcolor={bgColor}
      color={variantStyle[variant].color}
      p={padding}
      m={margin}
      mb={marginBottom}
      mt={marginTop}
      borderRadius={2}
      whiteSpace="pre-line" // Used to allow messages to be multiline strings.
      className={classes.box}
    >
      {icon && (
        <Box component="div" width="auto" m={0} pr={1}>
          {icon}
        </Box>
      )}
      <Box component="div" width="auto" m={0}>
        {messageHeader && (
          <Typography
            variant={'subtitle2'}
            style={{ color: color, marginBottom: '10px' }}
          >
            {messageHeader}
          </Typography>
        )}
        <Typography variant="body1">{message}</Typography>
      </Box>
    </Box>
  );
};

export const Message = memo<Props>(MessageComponent);
