// @flow
import { getInvariantUserAccount } from '@dt/session';
import React, { memo, useState } from 'react';
import ConfigurableLHSMenuHeader from './ConfigurableLHSMenuHeader';
import {
  Drawer,
  NotificationsDrawer,
  ToolkitIcon,
} from '@dt/material-components';
import { makeStyles } from '@material-ui/styles';
import ConfigurableLHSMenuRoutes from './ConfigurableLHSMenuRoutes';
import ConfigurableLSHMenuChevron from './ConfigurableLHSMenuChevron';
import ConfigurableLHSMenuDevSecOps from './ConfigurableLHSMenuDevSecOps';
import ConfigurableLHSMenuProductSwitcher from './ConfigurableLHSMenuProductSwitcher';
import ConfigurableLHSMenuSettings from './ConfigurableLHSMenuSettings';
import ConfigurableLHSMenuSkeleton from './ConfigurableLHSMenuSkeleton';
import WebSecureIcon from '@dt/brand/product-icon-web-secure';
import APISecureIcon from '@dt/brand/product-icon-api-secure';
import CloudSecureIcon from '@dt/brand/product-icon-cloud-secure';
import MobileSecureIcon from '@dt/brand/product-icon-mobile-secure';
import BrandIconSupplyChainSecurity from '@dt/brand/product-icon-supply-chain-security';
import DTIcon from '@dt/brand/dt-logo-icon-only';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GavelIcon from '@material-ui/icons/Gavel';
import WarningIcon from '@material-ui/icons/Warning';
import ProtectIcon from '@material-ui/icons/Security';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import ConfigurableLHSMenuSdu from './ConfigurableLHSMenuSdu';

export type DTProductIconTypes =
  | typeof WebSecureIcon
  | typeof APISecureIcon
  | typeof CloudSecureIcon
  | typeof MobileSecureIcon
  | typeof DTIcon; // cross-product

export type DTTopLevelIconTypes =
  | typeof DashboardIcon
  | typeof ToolkitIcon
  | typeof GroupWorkIcon
  | typeof GavelIcon
  | typeof WarningIcon
  | typeof ProtectIcon
  | typeof SmartphoneIcon;

export type LeftMenuSubLevelEntry = {|
  to: string,
  name: string,
  exact?: boolean,
  target?: '_self' | '_blank' | '_parent' | '_top',

  // Set to true if this menu's link redirects to a different
  // application within Obol e.g. link to API Secure from
  // Cloud Secure.
  redirect?: boolean,
  genericLink?: boolean,
|};

export type LeftMenuTopLevelEntry = {|
  icon: DTTopLevelIconTypes,
  name: string,
  to?: string | null, // Optional, if clickable
  exact?: boolean,
  target?: '_self' | '_blank' | '_parent' | '_top',
  subEntries?: $ReadOnlyArray<LeftMenuSubLevelEntry>,

  // Set to true if this menu's link redirects to a different
  // application within Obol e.g. link to API Secure from
  // Cloud Secure.
  redirect?: boolean,
|};

export type DTProductConfig = {|
  to: string,
  name: string,
  icon: DTProductIconTypes,
  redirect?: boolean,
  target?: '_self' | '_blank' | '_parent' | '_top',
|};

export type LeftMenuConfiguration = {|
  useMobileRouting?: boolean,
  product: DTProductConfig,
  entries?: $ReadOnlyArray<LeftMenuTopLevelEntry>,
|};

let useStyle = makeStyles({
  drawerContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
  },
});

type Props =
  | {| +isLoading: true |}
  | {|
      +isLoading?: false,
      +configuration: LeftMenuConfiguration,
    |};

function ConfigurableLHSMenuPage(props: Props) {
  const css = useStyle();
  const [navDrawerOpen, setNavDrawerOpen] = useState<boolean>(true);

  let content = null;
  if (props.isLoading) {
    content = <ConfigurableLHSMenuSkeleton />;
  } else {
    const { accountInfo, currentUser } = getInvariantUserAccount();
    const { product, useMobileRouting = false } = props.configuration;

    // hack => overwrite entries pass if user is not subscribed to SCS
    // session is not accessible before this level

    let scsMenuItem;
    if (currentUser?.can_access_vendor_supply_chain_security) {
      // if the current user can access Vendor SCS (regardless of whether they are
      // in their home account or a vendor-customer), then always send them to
      // the main vendor SCS page
      scsMenuItem = {
        to: '/management/scs',
        name: accountInfo?.vendor_supply_chain_security_whitelabeling_enabled
          ? accountInfo?.vendor_supply_chain_security_whitelabeling_name ||
            'Supply Chain Secure'
          : 'Supply Chain Secure',
        icon: BrandIconSupplyChainSecurity,
      };
    } else if (accountInfo?.current_vendor) {
      // if the current user can't access Vendor SCS, but they are inside of a
      // vendor-customer, then send them to their own vendor page.
      scsMenuItem = {
        to: `/management/scs/vendor/${accountInfo.vendor_id || ''}/`,
        name: accountInfo?.vendor_supply_chain_security_whitelabeling_enabled
          ? accountInfo?.vendor_supply_chain_security_whitelabeling_name ||
            'Supply Chain Secure'
          : 'Supply Chain Secure',
        icon: BrandIconSupplyChainSecurity,
      };
    } else {
      // otherwise, send the user to either normal SCS or OpenScan
      scsMenuItem =
        accountInfo.supply_chain_security_product_enabled ||
        accountInfo.openscan_subscription == 'NO_SUBSCRIPTION'
          ? {
              to: '/supply-chain',
              name: 'Supply Chain Secure',
              icon: BrandIconSupplyChainSecurity,
            }
          : {
              to: '/openscan',
              name: 'Supply Chain Security',
              icon: BrandIconSupplyChainSecurity,
            };
    }

    const entries =
      product.to === '/supply-chain' &&
      !accountInfo.supply_chain_security_product_enabled
        ? props.configuration.entries?.map(entry => ({
            ...entry,
            subEntries: entry.subEntries
              ? entry.subEntries.map(subEntry => ({
                  ...subEntry,
                  to: '/supply-chain#',
                }))
              : [],
            to: '/supply-chain#',
          }))
        : props.configuration.entries;

    const dtProducts: $ReadOnlyArray<DTProductConfig> = [
      {
        to: '/dashboard',
        name: 'Dashboard',
        icon: DTIcon,
      },

      {
        to: '/api',
        name: 'API Secure',
        icon: APISecureIcon,
      },

      {
        to: '/cloud',
        name: 'Cloud Secure',
        icon: CloudSecureIcon,
      },

      {
        to: '/mobile-secure/inventory/mobile-apps',
        name: 'Mobile Secure',
        icon: MobileSecureIcon,
      },

      {
        to: '/web',
        name: 'Web Secure',
        icon: WebSecureIcon,
      },

      scsMenuItem,
    ];

    const showHeader =
      product.to.indexOf('/management/products') !== -1 ||
      product.to.indexOf('/dashboard') !== -1;

    content = (
      <>
        {/* Header section of LHS Menu */}
        {showHeader && (
          <ConfigurableLHSMenuHeader
            icon={product.icon}
            name={product.name}
            to={product.to}
          />
        )}

        {/* Routes */}
        <ConfigurableLHSMenuRoutes
          product={product}
          menuItems={entries}
          drawerOpen={navDrawerOpen}
          isUsingMobileRouting={useMobileRouting}
          showHeader={showHeader}
        />

        {/* Chevron and other items below this pushed to bottom of page */}
        <ConfigurableLSHMenuChevron
          navDrawerOpen={navDrawerOpen}
          setNavDrawerOpen={setNavDrawerOpen}
        />

        {/* Notifications drawer */}
        <NotificationsDrawer isSidebarExpanded={navDrawerOpen} />

        <ConfigurableLHSMenuSdu isUsingMobileRouting={useMobileRouting} />

        {/* Product switcher */}
        <ConfigurableLHSMenuProductSwitcher products={dtProducts} />

        {/* DevSecOps */}
        <ConfigurableLHSMenuDevSecOps />

        {/* Settings */}
        <ConfigurableLHSMenuSettings />
      </>
    );
  }

  return (
    <Drawer open={navDrawerOpen}>
      <nav className={css.drawerContent}>{content}</nav>
    </Drawer>
  );
}

export default memo<Props>(ConfigurableLHSMenuPage);
