// @flow

import { Link, Route } from 'react-router-dom';
import React, { memo } from 'react';

import { ExtLink } from '../index';
import SduIcon from '@dt/brand/sduIcon.svg';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';

let useStyle = makeStyles({
  menuOuter: {
    alignItems: 'center',
    borderRadius: 4,
    color: palette.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    '&:hover': {
      backgroundColor: palette.brand20,
    },

    '&.active': {
      backgroundColor: palette.brand35,
    },

    '&.bottom': {
      marginTop: 'auto',
    },
  },

  menuInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  menuIconBox: {
    maxWidth: 40,
    maxHeight: 40,
    minWidth: 40,
    minHeight: 40,
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '16px',
  },

  menuText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
  },
});

type Props = {
  isUsingMobileRouting: boolean,
};

function ConfigurableLHSMenuSdu({ isUsingMobileRouting }: Props) {
  const styles = useStyle();

  // Mobile still uses react-router-dom instead of @reach/router
  return isUsingMobileRouting ? (
    <Route>
      <Link
        to="/devsecops/stanford-dish-security-university"
        className={styles.menuOuter}
      >
        <Tooltip title="Security Training">
          <div className={styles.menuInner}>
            <div className={styles.menuIconBox}>
              <img src={SduIcon} alt="sdu-icon" width="24" />
            </div>
            <div className={styles.menuText}>Security Training</div>
          </div>
        </Tooltip>
      </Link>
    </Route>
  ) : (
    <ExtLink
      target="_self"
      to="/devsecops/stanford-dish-security-university"
      className={styles.menuOuter}
    >
      <Tooltip title="Security Training">
        <div className={styles.menuInner}>
          <div className={styles.menuIconBox}>
            <img src={SduIcon} alt="sdu-icon" width="24" />
          </div>
          <div className={styles.menuText}>Security Training</div>
        </div>
      </Tooltip>
    </ExtLink>
  );
}

export default memo<Props>(ConfigurableLHSMenuSdu);
